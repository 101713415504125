<template>
    <auth-layout :title="$t('forgot_password.title')" :button-text="$t('forgot_password.button')" :action="submit">
        <validation-provider name="email" rules="required|email" v-slot="{ errors, reset }" slim>
            <input-group :errors="errors" :label="$t('validation.names.email')">
                <input-component v-model="email" type="email" :placeholder="$t('validation.names.email')" @input="reset" @mousedown.stop />
            </input-group>
        </validation-provider>

        <template #links>
            <router-link :to="{ name: 'login' }">{{ $t('forgot_password.login') }}</router-link>
        </template>
    </auth-layout>
</template>

<script>
    import { ValidationProvider } from 'vee-validate';
    import { forgotPassword } from '@/services/skinsPaymentApi';
    import AuthLayout from '@/pages/layout/AuthLayout';
    import InputComponent from '@/components/inputs/InputComponent';
    import InputGroup from '@/components/inputs/InputGroup';

    export default {
        name: 'ForgotPassword',
        components: {
            AuthLayout,
            InputComponent,
            InputGroup,
            ValidationProvider,
        },
        methods: {
            submit(recaptchaToken) {
                return forgotPassword({ email: this.email, 'g-recaptcha-response': recaptchaToken }).then(() => {
                    this.email = null;
                    this.$toast.success(this.$t('forgot_password.success'));
                });
            },
        },
        data() {
            return {
                email: null,
            };
        },
    };
</script>
